import { useState, useRef, useEffect } from 'react';
import {
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorModeValue,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  Divider,
} from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import {
  useChangePasswordMutation,
  User,
  UserInput,
  useUpdateUserMutation,
} from '../../__generated__/graphql';
import { imageStorage } from '../../utils/storage';

type EditUserProfileModalProps = {
  userData?: User | null;
};

export default function EditUserProfileModal({
  userData,
}: EditUserProfileModalProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [avatar, setAvatar] = useState<string | null>(userData?.picture || '');
  const [firstName, setFirstName] = useState(userData?.firstname || '');
  const [lastName, setLastName] = useState(userData?.lastname || '');
  const [email, setEmail] = useState(userData?.email);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [updateUser] = useUpdateUserMutation();
  const [changePassword] = useChangePasswordMutation();

  const initialRef = useRef(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (userData?.picture) {
      imageStorage.download(userData?.picture).then((response) => {
        setAvatar(response!);
      });
    }
  });

  const handleChangeAvatar = () => {
    fileInputRef?.current?.click();
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files?.length) {
      return;
    }

    const file = event.target.files[0];
    setAvatar(URL.createObjectURL(file));

    try {
      const imgPathId = `user/${userData?._id}/profile.jpg`;

      await imageStorage.upload(imgPathId, file);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSubmit = async () => {
    const user: UserInput = {
      firstname: firstName,
      lastname: lastName,
      email,
    };

    if (oldPassword && newPassword) {
      changePassword({
        variables: {
          email: userData!.email,
          old_password: oldPassword,
          new_password: newPassword,
        },
      });
    }

    await updateUser({
      variables: {
        _id: userData!._id,
        user: user,
      },
    });

    onClose();
  };

  return (
    <>
      <MenuItem onClick={onOpen}>Edit Profile</MenuItem>

      <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              spacing={4}
              w={'full'}
              maxW={'md'}
              bg={useColorModeValue('white', 'gray.700')}
              p={6}
            >
              <FormControl id="userName">
                <Stack direction={['column', 'row']} spacing={6}>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                  />
                  <Center>
                    <Avatar size="xl" src={avatar!}>
                      {avatar && (
                        <AvatarBadge
                          as={IconButton}
                          size="sm"
                          rounded="full"
                          top="-10px"
                          colorScheme="red"
                          aria-label="remove Image"
                          icon={<SmallCloseIcon />}
                          onClick={() => {
                            if (fileInputRef && fileInputRef.current) {
                              fileInputRef.current.value = '';
                            }
                            setAvatar('');
                          }}
                        />
                      )}
                    </Avatar>
                  </Center>
                  <Center w="full">
                    <Button w="full" onClick={handleChangeAvatar}>
                      Change Avatar
                    </Button>
                  </Center>
                </Stack>
              </FormControl>
              <FormControl id="firstName" isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  ref={initialRef}
                  placeholder="First Name"
                  _placeholder={{ color: 'gray.500' }}
                  type="text"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </FormControl>
              <FormControl id="lastName" isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  placeholder="Last Name"
                  _placeholder={{ color: 'gray.500' }}
                  type="text"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  placeholder="your-email@example.com"
                  _placeholder={{ color: 'gray.500' }}
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </FormControl>
              <Divider orientation="horizontal" />
              <FormControl id="oldPassword">
                <FormLabel>Old Password</FormLabel>
                <Input
                  placeholder="Old Password"
                  _placeholder={{ color: 'gray.500' }}
                  type="password"
                  value={oldPassword}
                  onChange={(event) => setOldPassword(event.target.value)}
                />
              </FormControl>
              <FormControl id="newPassword">
                <FormLabel>New Password</FormLabel>
                <Input
                  placeholder="New Password"
                  _placeholder={{ color: 'gray.500' }}
                  type="password"
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                />
              </FormControl>
              <Stack spacing={6} direction={['column', 'row']}>
                <Button
                  bg={'red.400'}
                  color={'white'}
                  w="full"
                  _hover={{
                    bg: 'red.500',
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  bg={'blue.400'}
                  color={'white'}
                  w="full"
                  _hover={{
                    bg: 'blue.500',
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
