import { Route, Routes, Navigate } from 'react-router-dom';
import { Dashboard } from './Dashboard';
import LoginPage from './Auth/LoginPage';
import ForgotPasswordPage from './Auth/ForgotPasswordPage';
import SignUpPage from './Auth/SignUpPage';
import ResetPasswordPage from './Auth/ResetPasswordPage';
import VerifyEmailPage from './Auth/VerifyEmailPage';
import EditUserProfilePage from './EditUserProfilePage';
import { EmployeesPage } from './Employees/EmployeesPage';
import { useAuth } from './App';
import BillingPage from './Billing/BillingPage';

const AppRoutes = () => {
  const ProtectedRoute = ({ children }: { children: React.ReactElement }) => {
    const { userID } = useAuth();


    if (!userID) {
      return <Navigate to="/login" replace />;
    }

    return children;
  };

  const AuthRoute = ({ children }: { children: React.ReactElement }) => {
    const { userID } = useAuth();

    if (userID) {
      return <Navigate to="/" replace />;
    }

    return children;
  };

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <AuthRoute>
            <LoginPage />
          </AuthRoute>
        }
      />
      <Route
        path="/sign-up"
        element={
          <AuthRoute>
            <SignUpPage />
          </AuthRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <AuthRoute>
            <ForgotPasswordPage />
          </AuthRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <AuthRoute>
            <ResetPasswordPage />
          </AuthRoute>
        }
      />
      <Route
        path="/verify-email"
        element={
          <AuthRoute>
            <VerifyEmailPage />
          </AuthRoute>
        }
      />
      <Route
        path="/edit-user-profile"
        element={
          <ProtectedRoute>
            <EditUserProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/employees"
        element={
          <ProtectedRoute>
            <EmployeesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/billing"
        element={
          <ProtectedRoute>
            <BillingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <EmployeesPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}

export default AppRoutes