import { Box, ComponentDefaultProps, ThemingProps, useStyleConfig } from "@chakra-ui/react";
import { ReactElement } from "react";

interface CardProps extends ComponentDefaultProps {
  children: ReactElement,
  variant?: ThemingProps
}


export default function Card({
  children,
  variant,
  ...rest
}: CardProps): JSX.Element {
  const styles = useStyleConfig("Card", { variant });
  // Pass the computed styles into the `__css` prop
  return (
    <Box __css={styles} {...rest} p={22} borderRadius={15} boxShadow={"rgb(0 0 0 / 2%) 0px 3.5px 5.5px"}>
      {children}
    </Box>
  );
}
