import { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorModeValue,
  Select,
} from '@chakra-ui/react';
import { Company, useImportEmployeesMutation } from '../../__generated__/graphql';
import { GraphQLError } from 'graphql';
import ErrorMessage from '../ErrorMessage';
import fetchUserByID from '../../data/queries/user/fetchUserByID';

type ImportEmployeesFileModalProps = {
  open: Boolean;
  companies?: Company[] | null
};

export default function ImportEmployeesFileModal({
  open,
  companies
}: ImportEmployeesFileModalProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedCompany, setSelectedCompany] = useState(companies?.[0]._id || '');
  const [file, setFile] = useState<File | null>(null);
  const [encodedFile, setEncodedFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [importEmployees] = useImportEmployeesMutation({ refetchQueries: [{ query: fetchUserByID }] });

  const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompany(event.target.value);
  };

  const encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = btoa(reader.result as string);
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(file);
    });
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);

      const base64EncodedFile = await encodeFileToBase64(selectedFile)
      setEncodedFile(base64EncodedFile);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError('')
    try {
      await importEmployees({
        variables: {
          _company_id: selectedCompany,
          base64_file: encodedFile,
          file_name: file?.name || ''
        },
      })
      setLoading(false);
      onClose()

    } catch (e) {
      const error = e as GraphQLError
      setError(error.message)
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) onOpen();
  }, [open, onOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Employees</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            bg={useColorModeValue('white', 'gray.700')}
            p={6}
          >
            {error && <ErrorMessage message={error} />}
            <FormControl id="company" isRequired>
              <FormLabel>Company</FormLabel>
              <Select onChange={handleCompanyChange} value={selectedCompany}>
                {companies?.map((company) => {
                  return (
                    <option value={company._id}>{company?.name}</option>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl id="employeesFile" isRequired>
              <FormLabel>Employees File</FormLabel>
              <Input
                placeholder="Employees File"
                _placeholder={{ color: 'gray.500' }}
                _hover={{
                  borderColor: "transparent",
                }}
                marginLeft={-4}
                type="file"
                onChange={handleFileUpload}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </FormControl>
            <Stack spacing={6} direction={['column', 'row']}>
              <Button
                bg={'red.400'}
                color={'white'}
                w="full"
                _hover={{
                  bg: 'red.500',
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                bg={'blue.400'}
                color={'white'}
                w="full"
                _hover={{
                  bg: 'blue.500',
                }}
                disabled={!file || loading}
                onClick={handleSubmit}
                isLoading={loading}
              >
                Import
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}