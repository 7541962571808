import { Flex, Text, useColorModeValue } from "@chakra-ui/react";

import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";
import BillingRow from "../Tables/BillingRow";

interface BillingInformationDataProps {
  name: string;
  company: string;
  email: string;
  number: string;
}

interface BillingInformationProps {
  title: String,
  data: BillingInformationDataProps[]
}

const BillingInformation = ({
  title, data
}: BillingInformationProps): JSX.Element => {
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card my={{ lg: "24px" }} me={{ lg: "24px" }} bg={"white"}>
      <Flex direction='column'>
        <CardHeader py='12px'>
          <Text color={textColor} fontSize='lg' fontWeight='bold'>
            {title}
          </Text>
        </CardHeader>
        <CardBody>
          <Flex direction='column' w='100%'>
            {data.map((row) => {
              return (
                <BillingRow
                  name={row.name}
                  company={row.company}
                  email={row.email}
                  number={row.number}
                />
              );
            })}
          </Flex>
        </CardBody>
      </Flex>
    </Card>
  );
};

export default BillingInformation;