import { Box, ComponentDefaultProps, ThemingProps, useStyleConfig } from "@chakra-ui/react";
import { ReactElement } from "react";

interface CardProps extends ComponentDefaultProps {
  children: ReactElement,
  variant?: ThemingProps
}


export default function CardHeader({
  children,
  variant,
  ...rest
}: CardProps): JSX.Element {
  const styles = useStyleConfig("CardHeader", { variant });
  // Pass the computed styles into the `__css` prop
  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  );
}
