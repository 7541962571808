import { Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";
import InvoicesRow from "../Tables/InvoicesRow";
import { IconType } from "react-icons";

interface InvoicesDataProps {
  date: string;
  code: string;
  price: string;
  logo: IconType;
  format: string;
}

interface InvoicesProps {
  title: String,
  data: InvoicesDataProps[]
}

const Invoices = ({
  title, data
}: InvoicesProps): JSX.Element => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card
      p='22px'
      my={{ sm: "24px", lg: "0px" }}
      ms={{ sm: "0px", lg: "24px" }}
      bg={"white"}
    >
      <>
        <CardHeader>
          <Flex justify='space-between' align='center' mb='1rem' w='100%'>
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
              {title}
            </Text>
            <Button
              colorScheme='teal'
              borderColor='teal.300'
              color='teal.300'
              variant='outline'
              fontSize='xs'
              p='8px 32px'>
              VIEW ALL
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction='column' w='100%'>
            {data.map((row) => {
              return (
                <InvoicesRow
                  date={row.date}
                  code={row.code}
                  price={row.price}
                  logo={row.logo}
                  format={row.format}
                />
              );
            })}
          </Flex>
        </CardBody>
      </>
    </Card>
  );
};

export default Invoices;
