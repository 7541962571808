import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react';
import { removeTypeNames } from '../../utils/strings';
import { Company, useFetchUserByIdQuery } from '../../__generated__/graphql';
import { useAuth } from '../App';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import TablesTableRow from './Tables/TablesTableRow';
import ImportEmployeesFileModal from './ImportEmployeesFileModal';

export const EmployeesPage = () => {
  const [selectedCompany, setSelectedCompany] = useState<Company>()
  const [openImportEmployeesFileModal, setOpenImportEmployeesFileModal] = useState<Boolean>(false)

  const textColor = useColorModeValue("gray.700", "white");
  const { user } = useAuth();
  const user_id = user?._id || ''

  const headerColumns = ["Name", "Email", "Status", "Credits", ""]

  const userResults = useFetchUserByIdQuery({
    variables: {
      _id: user_id,
    },
    skip: !user_id,
  });

  const userData = userResults.data?.userByID;

  useEffect(() => {
    setSelectedCompany(removeTypeNames(userData?.Company?.[0]))
  }, [userData, setSelectedCompany])

  const onCLickCompany = (company: Company) => {
    setSelectedCompany(company)
  }

  const renderEmployeesList = () => {
    return (
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} bg={"white"}>
        <>
          <CardHeader p='6px 0px 22px 0px'>
            <Text fontSize='xl' color={textColor} fontWeight='bold'>
              {`${selectedCompany?.name} Employees`}
            </Text>
          </CardHeader>
          <CardBody>
            <Table variant='simple' color={textColor}>
              <Thead>
                <Tr my='.8rem' pl='0px' color='gray.400'>
                  {headerColumns.map((caption, idx) => {
                    return (
                      <Th color='gray.400' key={idx} ps={idx === 0 ? 0 : 5}>
                        {caption}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {selectedCompany?.employees?.map((employee) => {
                  return (
                    <TablesTableRow
                    key={`${employee?.email}-${employee?.firstname}`}
                    rowData={employee}
                    company={selectedCompany}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
        </>
      </Card>
    );
  }

  const companyCard = (company?: Company | undefined) => {
    if (!company) return null

    return (
      <Card bg={company._id === selectedCompany?._id ? 'blue.100' : "white"} w={300}>
        <Stack onClick={() => onCLickCompany(company)} cursor={'pointer'}>
          <CardHeader>
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
              {company.name}
            </Text>
          </CardHeader>
          <CardBody>
            <Text color='gray.400' fontSize='md' fontWeight='semibold'>
              {`${company.employees?.length || 0} employees`}
            </Text>
          </CardBody>
        </Stack>
      </Card>
    )
  }
  const renderCompanies = () => {
    return (
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        mb={10}
      >
        <Stack direction={'row'} flexWrap={'wrap'}>
          {userData?.Company?.map((company) => {
            return companyCard(removeTypeNames(company))
          })}
        </Stack>
        <Button
          bg={'blue.400'}
          color={'white'}
          _hover={{
            bg: 'blue.500',
          }}
          onClick={() => setOpenImportEmployeesFileModal(!openImportEmployeesFileModal)}
        >
          Import Employees
        </Button>
        {openImportEmployeesFileModal && (
          <ImportEmployeesFileModal
            open={openImportEmployeesFileModal}
            companies={removeTypeNames(userData?.Company)}
          />
        )}
      </Stack>
    )
  }

  return (
    <>
      {renderCompanies()}
      {selectedCompany && renderEmployeesList()}
    </>
  )
}