import { ComponentDefaultProps, Flex } from "@chakra-ui/react";
import { ReactElement } from "react";

interface IconBoxProps extends ComponentDefaultProps {
  children: ReactElement
}


export default function IconBox({
  children,
  ...rest
}: IconBoxProps): JSX.Element {

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      borderRadius={"12px"}
      {...rest}
    >
      {children}
    </Flex>
  );
}
