import { ReactNode } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
} from '@chakra-ui/react';
import { FiMenu, FiBell, FiChevronDown, FiUser } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { Link as LinkRouter, useNavigate, To } from 'react-router-dom';
import { LogoPurple } from '../assets/LogoPurple';
import { LogoWhite } from '../assets/LogoWhite';
import { useAuth } from './App';

import EditUserProfileModal from './EditUserProfileModal';

interface MainContainerProps extends FlexProps {
  children: ReactNode;
}

interface LinkItemProps {
  name: string;
  icon: IconType;
  linkTo: To;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: String;
  linkTo: To;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Employees', icon: FiUser, linkTo: '/' },
  // { name: 'Employees', icon: FiUser, linkTo: "/employees" },
  // { name: 'Wallet', icon: FiTrendingUp, linkTo: "/edit-user-profile" },
  // { name: 'Billing', icon: FiCreditCard, linkTo: "/billing" },
];

export default function MainContainer({ children }: MainContainerProps) {
  const navigate = useNavigate();
  const { onLogout, userID, user } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();

  const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    return (
      <Box
        transition="3s ease"
        bg={useColorModeValue('white', 'gray.900')}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        w={{ base: 'full', md: 60 }}
        pos="fixed"
        h="full"
        {...rest}
      >
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <IconButton
            display={{ base: 'flex' }}
            width={100}
            variant="unstyled"
            aria-label="open menu"
            onClick={() => navigate('/')}
            icon={colorMode === 'light' ? <LogoPurple /> : <LogoWhite />}
          />
          <CloseButton
            display={{ base: 'flex', md: 'none' }}
            onClick={onClose}
          />
        </Flex>
        {LinkItems.map((link) => (
          <NavItem key={link.name} icon={link.icon} linkTo={link.linkTo}>
            {link.name}
          </NavItem>
        ))}
      </Box>
    );
  };

  const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
    return (
      <LinkRouter to={rest.linkTo}>
        <Link
          href="#"
          style={{ textDecoration: 'none' }}
          _focus={{ boxShadow: 'none' }}
        >
          <Flex
            align="center"
            p="4"
            mx="4"
            borderRadius="lg"
            role="group"
            cursor="pointer"
            _hover={{
              bg: 'cyan.400',
              color: 'white',
            }}
            {...rest}
          >
            {icon && (
              <Icon
                mr="4"
                fontSize="16"
                _groupHover={{
                  color: 'white',
                }}
                as={icon}
              />
            )}
            {children}
          </Flex>
        </Link>
      </LinkRouter>
    );
  };

  const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    return (
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue('white', 'gray.900')}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        justifyContent={{ base: 'space-between', md: 'flex-end' }}
        position={'sticky'}
        top={0}
        zIndex={100}
        {...rest}
      >
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <IconButton
          display={{ base: 'flex', md: 'none' }}
          width={70}
          variant="unstyled"
          aria-label="open menu"
          onClick={() => navigate('/')}
          icon={colorMode === 'light' ? <LogoPurple /> : <LogoWhite />}
        />

        <HStack>
          <IconButton
            size="lg"
            variant="ghost"
            aria-label="open menu"
            onClick={toggleColorMode}
            icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          />
          <IconButton
            size="lg"
            variant="ghost"
            aria-label="open menu"
            icon={<FiBell />}
          />
          <Flex alignItems={'center'} pl={2}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: 'none' }}
              >
                <HStack>
                  <Avatar size={'sm'} src={user?.picture || ''} />
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">{user?.firstname}</Text>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue('white', 'gray.900')}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
              >
                <EditUserProfileModal userData={user} />
                <MenuDivider />
                <MenuItem onClick={() => onLogout()}>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
    );
  };

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.200', 'gray.900')}>
      {userID ? (
        <>
          <SidebarContent
            onClose={() => onClose}
            display={{ base: 'none', md: 'block' }}
          />
          <Drawer
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full"
          >
            <DrawerContent>
              <SidebarContent onClose={onClose} />
            </DrawerContent>
          </Drawer>
          <MobileNav onOpen={onOpen} />
          <Box ml={{ base: 0, md: 60 }} p="6" overflow={'auto'}>
            {children}
          </Box>
        </>
      ) : (
        children
      )}
    </Box>
  );
}
