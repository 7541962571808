import { gql } from '@apollo/client';

export default gql`
  query FetchUserByID($_id: ID!) {
    userByID(_id: $_id) {
      _id
      email
      firstname
      lastname
      picture
      Company {
        _id
        name
        employees {
          _id
          firstname
          lastname
          email
          wallet {
            credits
          }
        }
        users {
          _id
          status
        }
      }
    }
  }
`;
