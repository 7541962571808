import { createContext } from 'react'
import { User } from '../../__generated__/graphql'

export interface AuthContextInterface {
  userID?: String
  user?: User | null
  onLogin: (user: User) => Promise<void>
  onLogout: () => Promise<void>  | void
}

const contextError = 'AuthContext not yet initialized'

export default createContext<AuthContextInterface>({
  userID: '',
  user: null,
  onLogin: () => {
    throw new Error(contextError)
  },
  onLogout: () => {
    throw new Error(contextError)
  },
})
