import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import { useContext } from "react";
import AuthContext from "./Auth/AuthContext";
import AuthProvider from "./Auth/AuthProvider";
import MainContainer from "./MainContainer";

import AppRoutes from './Routes';

export const useAuth = () => {
  return useContext(AuthContext);
};

export const App = () => (
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <MainContainer>
        <AppRoutes />
      </MainContainer>
    </AuthProvider>
  </ChakraProvider>
)
