import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
  InputRightElement,
  InputGroup,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useLoginAdminMutation } from "../../__generated__/graphql";
import { GraphQLError } from "graphql";
import ErrorMessage from "../ErrorMessage";
import { useAuth } from "../App";

export default function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const { onLogin } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [loginAdmin] = useLoginAdminMutation();

  const handleLogin = async (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();

    setLoading(true);
    try {
      const loginData = await loginAdmin({
        variables: {
          email: email,
          password: password,
        },
      })

      const userData = loginData?.data?.loginAdmin

      if (userData) {
        onLogin(userData)
        setLoading(false);
      }
    } catch (e) {
      const error = e as GraphQLError
      setError(error.message)
      setLoading(false);
    }
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.300', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
        </Stack>
        <form onSubmit={handleLogin}>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
          >
            {error && <ErrorMessage message={error} />}
            <Stack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  onChange={event => setEmail(event.currentTarget.value)}
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    onChange={event => setPassword(event.currentTarget.value)}
                  />
                  <InputRightElement h={'full'}>
                    <Button
                      variant={'ghost'}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'flex-end'}>
                  <Link
                    color={'blue.400'}
                    onClick={() => navigate('/forgot-password')}
                  >
                    Forgot password?
                  </Link>
                </Stack>
                <Button
                  type="submit"
                  isLoading={loading}
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Sign in
                </Button>
              </Stack>
              <Stack align={'center'} pt={6}>
                <Box>
                  New to us?{" "}
                  <Link
                    color="teal.500" href="#"
                    onClick={() => navigate('/sign-up')}
                  >
                    Sign Up
                  </Link>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </form>
      </Stack>
    </Flex>
  );
}