import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import {
  useCreateUserMutation,
  useFetchUserByEmailLazyQuery,
  useLoginAdminMutation,
  useUpdateUserMutation,
  UserType,
} from '../../__generated__/graphql';
import { GraphQLError } from 'graphql';
import { useAuth } from '../App';
import ErrorMessage from '../ErrorMessage';

export default function SignUpPage() {
  const navigate = useNavigate();
  const { onLogin } = useAuth();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [fetchUserByEmail] = useFetchUserByEmailLazyQuery();
  const [loginAdmin] = useLoginAdminMutation();
  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();

  const handleSignUp = async (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    // Method should check whether the user already exists
    // Yes: Validate login and redirect to Home page
    //  No: Create a new user and redirect to finish profile page
    setLoading(true);
    const userByEmail = await fetchUserByEmail({
      variables: {
        email: email,
      },
    });
    // If it exists, it should login
    try {
      if (userByEmail?.data?.userByEmail) {
        const loginData = await loginAdmin({
          variables: {
            email: email,
            password: password,
          },
        })

        const userData = loginData?.data?.loginAdmin
        if (userData) {
          onLogin(userData);
          setLoading(false);
        }

      } else {
        // Create account
        const createdUserData = await createUser({
          variables: {
            user: {
              email: email,
              password: password,
              type: UserType.Company,
            },
          },
        });

        const userData = createdUserData?.data?.createUser
        if (userData) {
          const updateUserData = await updateUser({
            variables: {
              _id: userData._id,
              user: {
                firstname: firstName,
                lastname: lastName,
                type: UserType.Company,
              },
            },
          })

          const updatedUser = updateUserData?.data?.updateUser
          if (updatedUser) {
            onLogin(updatedUser);
            setLoading(false);
          }
        }
      }
    } catch (e) {
      const error = e as GraphQLError
      setError(error.message)
      setLoading(false);
    }
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.300', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Sign up
          </Heading>
        </Stack>
        <form onSubmit={handleSignUp}>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
          >
            {error && <ErrorMessage message={error} />}
            <Stack spacing={4}>
              <HStack>
                <Box>
                  <FormControl id="firstName" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      onChange={event => setFirstName(event.currentTarget.value)}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="lastName">
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      onChange={event => setLastName(event.currentTarget.value)}
                    />
                  </FormControl>
                </Box>
              </HStack>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  onChange={event => setEmail(event.currentTarget.value)}
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    onChange={event => setPassword(event.currentTarget.value)}
                  />
                  <InputRightElement h={'full'}>
                    <Button
                      variant={'ghost'}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={10} pt={2}>
                <Button
                  type='submit'
                  loadingText="Submitting"
                  size="lg"
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  isLoading={loading}
                >
                  Sign up
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={'center'}>
                  Already a user? <Link color={'blue.400'} onClick={() => navigate('/login')}>Login</Link>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </form>
      </Stack>
    </Flex>
  );
}