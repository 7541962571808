import capitalize from 'lodash/capitalize'


const snakeCaseToTitleCase = (text: string) => {
    return text && text.split('_').map(capitalize).join(' ')
}

const removeTypeNames = (value: any): any => {
    if (value === null || value === undefined) {
        return value;
    }
    if (Array.isArray(value)) {
        return value.map((v) => removeTypeNames(v));
    }
    if (typeof value === 'object') {
        const newObj: any = {};
        Object.entries(value).forEach(([key, v]) => {
            if (key !== '__typename') {
                newObj[key] = removeTypeNames(v);
            }
        });
        return newObj;
    }
    return value;
}

const newObjectId = () => {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
    const objectId = timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
        return Math.floor(Math.random() * 16).toString(16);
    }).toLowerCase();

    return objectId;
}

export { snakeCaseToTitleCase, removeTypeNames, newObjectId };
