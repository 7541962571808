import { Auth, Storage } from 'aws-amplify';

const localStorage = {
  save(id: string, value: string) {
    window.localStorage.setItem(id, value);
  },
  get(id: string) {
    return window.localStorage.getItem(id);
  },
  getAll() {
    return { ...window.localStorage };
  },
  remove(id: string) {
    return window.localStorage.removeItem(id);
  },
  reset() {
    window.localStorage.clear();
  },
};

const imageStorage = {
  async upload(filename: string, img: unknown) {
    Auth.currentCredentials();
    return Storage.put(filename, img, {
      level: 'public',
      contentType: 'image/jpeg',
    })
      .then((response) => {
        return response.key;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async download(uri: string) {
    return Storage.get(uri)
      .then((response) => response)
      .catch((error) => console.log({ error }));
  },
};

export { localStorage, imageStorage };
