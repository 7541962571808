import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { localStorage } from "../../utils/storage";
import { User } from "../../__generated__/graphql";
import AuthContext, { AuthContextInterface } from "./AuthContext";



const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userID, setUserID] = useState('');
  const [user, setUser] = useState<User>();

  const loadUser = (user?: User) => {
    const savedUser = localStorage.get("user")
    if (user || savedUser) {
      const userData = user || (savedUser && JSON.parse(savedUser))
      setUser(userData)
      setUserID(userData._id)
    }
  }

  useEffect(() => {
    loadUser()
  }, [])

  const context = useMemo<AuthContextInterface>(
    () => ({
      userID,
      user,
      onLogin: async (
        user: User
      ) => {
        localStorage.save("userID", user._id)
        localStorage.save("user", JSON.stringify({
          _id: user._id,
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
        }));

        setUser(user)
        setUserID(user._id)

        const origin = location.state?.from?.pathname || '/';
        navigate(origin);
      },
      onLogout: async () => {
        setUserID('');
        setUser(undefined);
        localStorage.remove('userID');
        localStorage.remove('user');
        navigate("/login")
      },
    }),
    [userID, user, setUser, setUserID, navigate, location]
  )

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;