import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  Upload: any;
};

export type Address = {
  __typename?: 'Address';
  _id: Scalars['ID'];
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  formatted_address?: Maybe<Scalars['String']>;
  is_current?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationType>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type: AddressType;
  zip_code?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  _id: Scalars['ID'];
  city?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  formatted_address?: InputMaybe<Scalars['String']>;
  is_current?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<LocationInput>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  type: AddressType;
  zip_code?: InputMaybe<Scalars['String']>;
};

export enum AddressType {
  Gps = 'gps',
  Home = 'home',
  Other = 'other',
  Work = 'work'
}

export type AvailableTime = {
  __typename?: 'AvailableTime';
  end?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
};

export type AvailableTimeInput = {
  end?: InputMaybe<Scalars['Float']>;
  start?: InputMaybe<Scalars['Float']>;
};

export type Booking = {
  __typename?: 'Booking';
  _customer_id: Scalars['ID'];
  _id: Scalars['ID'];
  _specialist_id: Scalars['ID'];
  booking_date?: Maybe<Scalars['Float']>;
  booking_duration?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Float']>;
  customer?: Maybe<User>;
  customer_address?: Maybe<Address>;
  location_type?: Maybe<LocationTypes>;
  notes?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Maybe<BookingService>>>;
  specialist_information?: Maybe<SpecialistInformation>;
  status: BookingStatus;
  total?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['Float']>;
};

export type BookingInput = {
  _customer_id: Scalars['ID'];
  _specialist_id: Scalars['ID'];
  booking_date?: InputMaybe<Scalars['Float']>;
  customer_address?: InputMaybe<AddressInput>;
  location_type?: InputMaybe<LocationTypes>;
  notes?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<Array<InputMaybe<BookingServiceInput>>>;
  status: BookingStatus;
  total?: InputMaybe<Scalars['Float']>;
};

export type BookingService = {
  __typename?: 'BookingService';
  _service_id: Scalars['ID'];
  _specialist_service_id: Scalars['ID'];
  specialist_service?: Maybe<SpecialistService>;
};

export type BookingServiceInput = {
  _service_id: Scalars['ID'];
  _specialist_service_id: Scalars['ID'];
};

export enum BookingStatus {
  Active = 'active',
  CancelledByCustomer = 'cancelled_by_customer',
  CancelledBySpecialist = 'cancelled_by_specialist',
  Completed = 'completed',
  Declined = 'declined',
  Draft = 'draft',
  InReview = 'in_review',
  Removed = 'removed'
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Company = {
  __typename?: 'Company';
  _id: Scalars['ID'];
  abn_number?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  bsb_number?: Maybe<Scalars['String']>;
  category?: Maybe<Array<Maybe<CompanyCategory>>>;
  created?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employees?: Maybe<Array<Maybe<User>>>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<CompanyStatus>;
  type: CompanyType;
  updated?: Maybe<Scalars['Float']>;
  users?: Maybe<Array<Maybe<CompanyUsersType>>>;
  wallet?: Maybe<CompanyWalletType>;
  website?: Maybe<Scalars['String']>;
  working_hour?: Maybe<WorkingHour>;
};

export enum CompanyCategory {
  Barber = 'barber',
  Company = 'company',
  Other = 'other',
  Saloon = 'saloon'
}

export type CompanyInput = {
  abn_number?: InputMaybe<Scalars['String']>;
  account_number?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressInput>;
  bsb_number?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Array<InputMaybe<CompanyCategory>>>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CompanyStatus>;
  type: CompanyType;
  website?: InputMaybe<Scalars['String']>;
  working_hour?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
};

export enum CompanyStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export enum CompanyType {
  Company = 'company',
  Specialist = 'specialist'
}

export enum CompanyUserStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type CompanyUsersType = {
  __typename?: 'CompanyUsersType';
  _id?: Maybe<Scalars['ID']>;
  status?: Maybe<CompanyUserStatus>;
};

export type CompanyWalletType = {
  __typename?: 'CompanyWalletType';
  credits?: Maybe<Scalars['Int']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  type?: InputMaybe<UserType>;
};

export type CreditActivity = {
  __typename?: 'CreditActivity';
  _booking_id?: Maybe<Scalars['ID']>;
  _company_id?: Maybe<Scalars['ID']>;
  _id: Scalars['ID'];
  _transaction_id?: Maybe<Scalars['ID']>;
  _user_id?: Maybe<Scalars['ID']>;
  /**  Debit (User balance goes down), Credit (User balance goes up)  */
  accounting_type?: Maybe<CreditActivityAccountingTypes>;
  amount: Scalars['Float'];
  created?: Maybe<Scalars['Float']>;
  type?: Maybe<CreditActivityTypes>;
};

export enum CreditActivityAccountingTypes {
  Credit = 'credit',
  Debit = 'debit'
}

export type CreditActivityInput = {
  _booking_id?: InputMaybe<Scalars['ID']>;
  _company_id?: InputMaybe<Scalars['ID']>;
  _transaction_id?: InputMaybe<Scalars['ID']>;
  _user_id?: InputMaybe<Scalars['ID']>;
  accounting_type?: InputMaybe<CreditActivityAccountingTypes>;
  amount: Scalars['Float'];
  type?: InputMaybe<CreditActivityTypes>;
};

export enum CreditActivityTypes {
  AdminAdjustment = 'admin_adjustment',
  Purchase = 'purchase',
  Refund = 'refund',
  Usage = 'usage'
}

export type Credits = {
  __typename?: 'Credits';
  _id: Scalars['ID'];
  price?: Maybe<Array<Maybe<CreditsPriceType>>>;
  quantity?: Maybe<Scalars['Int']>;
  type?: Maybe<CreditsTypeEnum>;
};

export type CreditsPriceType = {
  __typename?: 'CreditsPriceType';
  currency_code?: Maybe<Scalars['String']>;
  currency_symbol?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export enum CreditsTypeEnum {
  Adhoc = 'adhoc',
  Pack = 'pack'
}

export enum DayOfTheWeek {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Weekends = 'weekends',
  WorkDays = 'work_days'
}

export type LocationInput = {
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  type?: InputMaybe<Scalars['String']>;
};

export type LocationType = {
  __typename?: 'LocationType';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  type?: Maybe<Scalars['String']>;
};

export enum LocationTypes {
  SpecialistAddress = 'specialist_address',
  UserAddress = 'user_address'
}

export type Mutation = {
  __typename?: 'Mutation';
  addCompanyUser?: Maybe<Company>;
  approveBooking?: Maybe<Booking>;
  changePassword?: Maybe<User>;
  changePasswordByRequestID?: Maybe<User>;
  changeStatus?: Maybe<RootSchema>;
  createBooking?: Maybe<Booking>;
  createCompany?: Maybe<Company>;
  createCreditActivity?: Maybe<CreditActivity>;
  createService?: Maybe<Service>;
  createServiceCategory?: Maybe<ServiceCategory>;
  createServiceSubcategory?: Maybe<ServiceSubcategory>;
  createSpecialistInformation?: Maybe<SpecialistInformation>;
  createSpecialistService?: Maybe<SpecialistService>;
  createUser?: Maybe<User>;
  deleteAccount?: Maybe<User>;
  deleteBooking?: Maybe<Booking>;
  deleteService?: Maybe<Service>;
  deleteSpecialistService?: Maybe<SpecialistService>;
  finishRegistration?: Maybe<User>;
  forgotPassword?: Maybe<User>;
  importEmployees?: Maybe<Company>;
  login?: Maybe<User>;
  loginAdmin?: Maybe<User>;
  rejectBooking?: Maybe<Booking>;
  updateBooking?: Maybe<Booking>;
  updateCompany?: Maybe<Company>;
  updateService?: Maybe<Service>;
  updateServiceCategory?: Maybe<ServiceCategory>;
  updateServiceSubcategory?: Maybe<ServiceSubcategory>;
  updateSpecialistInformation?: Maybe<SpecialistInformation>;
  updateSpecialistService?: Maybe<SpecialistService>;
  updateUser?: Maybe<User>;
};


export type MutationAddCompanyUserArgs = {
  _id: Scalars['ID'];
  email: Scalars['String'];
};


export type MutationApproveBookingArgs = {
  _id: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  email: Scalars['String'];
  new_password: Scalars['String'];
  old_password: Scalars['String'];
};


export type MutationChangePasswordByRequestIdArgs = {
  email: Scalars['String'];
  new_password: Scalars['String'];
  request_id: Scalars['Int'];
};


export type MutationCreateBookingArgs = {
  booking: BookingInput;
};


export type MutationCreateCompanyArgs = {
  company: CompanyInput;
};


export type MutationCreateCreditActivityArgs = {
  credit_activity: CreditActivityInput;
};


export type MutationCreateServiceArgs = {
  service: ServiceInput;
};


export type MutationCreateServiceCategoryArgs = {
  service_category: ServiceCategoryInput;
};


export type MutationCreateServiceSubcategoryArgs = {
  service_subcategory: ServiceSubcategoryInput;
};


export type MutationCreateSpecialistInformationArgs = {
  specialist_information: SpecialistInformationInput;
};


export type MutationCreateSpecialistServiceArgs = {
  specialist_service: SpecialistServiceInput;
};


export type MutationCreateUserArgs = {
  user: CreateUserInput;
};


export type MutationDeleteAccountArgs = {
  _id: Scalars['ID'];
};


export type MutationDeleteBookingArgs = {
  _id: Scalars['ID'];
};


export type MutationDeleteServiceArgs = {
  _id: Scalars['ID'];
};


export type MutationDeleteSpecialistServiceArgs = {
  _id: Scalars['ID'];
};


export type MutationFinishRegistrationArgs = {
  _id: Scalars['ID'];
  user: UserInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationImportEmployeesArgs = {
  _company_id: Scalars['ID'];
  base64_file: Scalars['String'];
  file_name: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginAdminArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRejectBookingArgs = {
  _id: Scalars['ID'];
};


export type MutationUpdateBookingArgs = {
  _id: Scalars['ID'];
  booking: BookingInput;
};


export type MutationUpdateCompanyArgs = {
  _id: Scalars['ID'];
  company: CompanyInput;
};


export type MutationUpdateServiceArgs = {
  _id: Scalars['ID'];
  service: ServiceInput;
};


export type MutationUpdateServiceCategoryArgs = {
  _id: Scalars['ID'];
  service_category: ServiceCategoryInput;
};


export type MutationUpdateServiceSubcategoryArgs = {
  _id: Scalars['ID'];
  service_subcategory: ServiceSubcategoryInput;
};


export type MutationUpdateSpecialistInformationArgs = {
  _id: Scalars['ID'];
  specialist_information: SpecialistInformationInput;
};


export type MutationUpdateSpecialistServiceArgs = {
  _id: Scalars['ID'];
  specialist_service: SpecialistServiceInput;
};


export type MutationUpdateUserArgs = {
  _id: Scalars['ID'];
  user: UserInput;
};

export type PasswordChangeRequest = {
  __typename?: 'PasswordChangeRequest';
  _id: Scalars['ID'];
  created?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  appVersion?: Maybe<Scalars['String']>;
  bookingByID?: Maybe<Booking>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  bookingsByCustomerID?: Maybe<Array<Maybe<Booking>>>;
  bookingsBySpecialistID?: Maybe<Array<Maybe<Booking>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  companyByID?: Maybe<Company>;
  creditActivityByID?: Maybe<CreditActivity>;
  creditPacks?: Maybe<Array<Maybe<Credits>>>;
  getStatus?: Maybe<Array<Maybe<RootSchema>>>;
  mostPopularSpecialists?: Maybe<Array<Maybe<User>>>;
  passwordChangeRequestByRequestID?: Maybe<PasswordChangeRequest>;
  serviceByID?: Maybe<Service>;
  serviceCategories?: Maybe<Array<Maybe<ServiceCategory>>>;
  serviceCategoryByID?: Maybe<ServiceCategory>;
  serviceSubcategories?: Maybe<Array<Maybe<ServiceSubcategory>>>;
  serviceSubcategoriesByServiceCategoryID?: Maybe<Array<Maybe<ServiceSubcategory>>>;
  serviceSubcategoryByID?: Maybe<ServiceSubcategory>;
  services?: Maybe<Array<Maybe<Service>>>;
  servicesByServiceCategoryID?: Maybe<Array<Maybe<Service>>>;
  servicesByServiceSubcategoryID?: Maybe<Array<Maybe<Service>>>;
  specialistInformationByCompanyID?: Maybe<Array<Maybe<SpecialistInformation>>>;
  specialistInformationByID?: Maybe<SpecialistInformation>;
  specialistInformationBySpecialistID?: Maybe<SpecialistInformation>;
  specialistServiceByID?: Maybe<SpecialistService>;
  specialistServices?: Maybe<Array<Maybe<SpecialistService>>>;
  specialistServicesByServiceCategoryID?: Maybe<Array<Maybe<SpecialistService>>>;
  specialistServicesByServiceSubcategoryID?: Maybe<Array<Maybe<SpecialistService>>>;
  specialistServicesBySpecialistID?: Maybe<Array<Maybe<SpecialistService>>>;
  specialists?: Maybe<Array<Maybe<User>>>;
  specialistsNearYou?: Maybe<Array<Maybe<User>>>;
  userByEmail?: Maybe<User>;
  userByID?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryAppVersionArgs = {
  app: Scalars['String'];
};


export type QueryBookingByIdArgs = {
  _id: Scalars['ID'];
};


export type QueryBookingsByCustomerIdArgs = {
  _customer_id: Scalars['ID'];
};


export type QueryBookingsBySpecialistIdArgs = {
  _specialist_id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  only_active_bookings?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCompanyByIdArgs = {
  _id: Scalars['ID'];
};


export type QueryCreditActivityByIdArgs = {
  _id: Scalars['ID'];
};


export type QueryCreditPacksArgs = {
  type?: InputMaybe<CreditsTypeEnum>;
};


export type QueryMostPopularSpecialistsArgs = {
  _service_category_id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  location_type: LocationTypes;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryPasswordChangeRequestByRequestIdArgs = {
  email: Scalars['String'];
  request_id: Scalars['Int'];
};


export type QueryServiceByIdArgs = {
  _id: Scalars['ID'];
};


export type QueryServiceCategoryByIdArgs = {
  _id: Scalars['ID'];
};


export type QueryServiceSubcategoriesByServiceCategoryIdArgs = {
  _service_category_id: Scalars['ID'];
};


export type QueryServiceSubcategoryByIdArgs = {
  _id: Scalars['ID'];
};


export type QueryServicesByServiceCategoryIdArgs = {
  _service_category_id: Scalars['ID'];
};


export type QueryServicesByServiceSubcategoryIdArgs = {
  _service_subcategory_id: Scalars['ID'];
};


export type QuerySpecialistInformationByCompanyIdArgs = {
  _company_id: Scalars['ID'];
};


export type QuerySpecialistInformationByIdArgs = {
  _id: Scalars['ID'];
};


export type QuerySpecialistInformationBySpecialistIdArgs = {
  _specialist_id: Scalars['ID'];
};


export type QuerySpecialistServiceByIdArgs = {
  _id: Scalars['ID'];
};


export type QuerySpecialistServicesByServiceCategoryIdArgs = {
  _service_category_id: Scalars['ID'];
};


export type QuerySpecialistServicesByServiceSubcategoryIdArgs = {
  _service_subcategory_id: Scalars['ID'];
};


export type QuerySpecialistServicesBySpecialistIdArgs = {
  _specialist_id: Scalars['ID'];
};


export type QuerySpecialistsNearYouArgs = {
  _service_category_id?: InputMaybe<Scalars['ID']>;
  coordinates: Array<InputMaybe<Scalars['Float']>>;
  limit?: InputMaybe<Scalars['Int']>;
  location_type: LocationTypes;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryUserByIdArgs = {
  _id: Scalars['ID'];
};

export type RootSchema = {
  __typename?: 'RootSchema';
  status?: Maybe<Scalars['Boolean']>;
};

export type Service = {
  __typename?: 'Service';
  _id: Scalars['ID'];
  _service_category_id: Scalars['ID'];
  _service_subcategory_id: Scalars['ID'];
  created?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  service_bookings_count?: Maybe<Scalars['Int']>;
  service_category?: Maybe<ServiceCategory>;
  service_subcategory?: Maybe<ServiceSubcategory>;
  status?: Maybe<ServiceStatus>;
  updated?: Maybe<Scalars['Float']>;
};

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  _id: Scalars['ID'];
  created?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  status?: Maybe<ServiceCategoryStatus>;
  updated?: Maybe<Scalars['Float']>;
};

export type ServiceCategoryInput = {
  name: Scalars['String'];
  status?: InputMaybe<ServiceCategoryStatus>;
};

export enum ServiceCategoryStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type ServiceInput = {
  _service_category_id: Scalars['ID'];
  _service_subcategory_id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<ServiceStatus>;
};

export enum ServiceStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type ServiceSubcategory = {
  __typename?: 'ServiceSubcategory';
  _id: Scalars['ID'];
  _service_category_id: Scalars['ID'];
  created?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  service_category?: Maybe<ServiceCategory>;
  status: ServiceSubcategoryStatus;
  updated?: Maybe<Scalars['Float']>;
};

export type ServiceSubcategoryInput = {
  _service_category_id: Scalars['ID'];
  name: Scalars['String'];
  status: ServiceSubcategoryStatus;
};

export enum ServiceSubcategoryStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type SpecialistInformation = {
  __typename?: 'SpecialistInformation';
  _company_id?: Maybe<Scalars['ID']>;
  _id: Scalars['ID'];
  _specialist_id: Scalars['ID'];
  address?: Maybe<Address>;
  business_name: Scalars['String'];
  created?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  location_types?: Maybe<Array<Maybe<LocationTypes>>>;
  phone?: Maybe<Scalars['String']>;
  /**  Photos that get displayed on the specialist details page  */
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
  specialist?: Maybe<User>;
  updated?: Maybe<Scalars['Float']>;
  website?: Maybe<Scalars['String']>;
  working_hour?: Maybe<Array<Maybe<WorkingHour>>>;
};

export type SpecialistInformationInput = {
  _company_id?: InputMaybe<Scalars['ID']>;
  _specialist_id?: InputMaybe<Scalars['ID']>;
  address?: InputMaybe<AddressInput>;
  business_name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  location_types?: InputMaybe<Array<InputMaybe<LocationTypes>>>;
  phone?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  website?: InputMaybe<Scalars['String']>;
  working_hour?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
};

export type SpecialistService = {
  __typename?: 'SpecialistService';
  _id: Scalars['ID'];
  _service_category_id: Scalars['ID'];
  _service_id: Scalars['ID'];
  _service_subcategory_id: Scalars['ID'];
  _specialist_id: Scalars['ID'];
  created?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  service?: Maybe<Service>;
  service_bookings_count?: Maybe<Scalars['Int']>;
  service_category?: Maybe<ServiceCategory>;
  service_subcategory?: Maybe<ServiceSubcategory>;
  specialist?: Maybe<User>;
  status?: Maybe<SpecialistServiceStatus>;
  updated?: Maybe<Scalars['Float']>;
};

export type SpecialistServiceInput = {
  _service_category_id: Scalars['ID'];
  _service_id: Scalars['ID'];
  _service_subcategory_id: Scalars['ID'];
  _specialist_id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<SpecialistServiceStatus>;
};

export enum SpecialistServiceStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type User = {
  __typename?: 'User';
  Company?: Maybe<Array<Maybe<Company>>>;
  _id: Scalars['ID'];
  addresses?: Maybe<Array<Maybe<Address>>>;
  birthdate?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  expo_push_token?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  specialist_information?: Maybe<SpecialistInformation>;
  status?: Maybe<UserStatus>;
  type?: Maybe<UserType>;
  updated?: Maybe<Scalars['Float']>;
  wallet?: Maybe<UserWalletType>;
};

export type UserInput = {
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>;
  birthdate?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  expo_push_token?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  type?: InputMaybe<UserType>;
};

export enum UserStatus {
  Active = 'active',
  Banned = 'banned',
  Deleted = 'deleted',
  Inactive = 'inactive',
  NotOnboarded = 'not_onboarded'
}

export enum UserType {
  Company = 'company',
  Specialist = 'specialist',
  User = 'user'
}

export type UserWalletType = {
  __typename?: 'UserWalletType';
  credits?: Maybe<Scalars['Int']>;
};

export type WorkingHour = {
  __typename?: 'WorkingHour';
  available_time?: Maybe<AvailableTime>;
  day_of_the_week?: Maybe<DayOfTheWeek>;
};

export type WorkingHourInput = {
  available_time?: InputMaybe<AvailableTimeInput>;
  day_of_the_week?: InputMaybe<DayOfTheWeek>;
};

export type ImportEmployeesMutationVariables = Exact<{
  _company_id: Scalars['ID'];
  base64_file: Scalars['String'];
  file_name: Scalars['String'];
}>;


export type ImportEmployeesMutation = { __typename?: 'Mutation', importEmployees?: { __typename?: 'Company', _id: string } | null };

export type ChangePasswordMutationVariables = Exact<{
  email: Scalars['String'];
  old_password: Scalars['String'];
  new_password: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword?: { __typename?: 'User', _id: string } | null };

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', _id: string, email: string, firstname?: string | null, lastname?: string | null } | null };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword?: { __typename?: 'User', _id: string } | null };

export type LoginAdminMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginAdminMutation = { __typename?: 'Mutation', loginAdmin?: { __typename?: 'User', _id: string, email: string, picture?: string | null, firstname?: string | null, lastname?: string | null } | null };

export type UpdateUserMutationVariables = Exact<{
  _id: Scalars['ID'];
  user: UserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', _id: string, email: string, firstname?: string | null, lastname?: string | null } | null };

export type FetchCompanyByIdQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;


export type FetchCompanyByIdQuery = { __typename?: 'Query', companyByID?: { __typename?: 'Company', _id: string, name: string, users?: Array<{ __typename?: 'CompanyUsersType', _id?: string | null, status?: CompanyUserStatus | null } | null> | null } | null };

export type FetchUserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type FetchUserByEmailQuery = { __typename?: 'Query', userByEmail?: { __typename?: 'User', _id: string, email: string, firstname?: string | null, lastname?: string | null, picture?: string | null, addresses?: Array<{ __typename?: 'Address', _id: string, type: AddressType, name?: string | null, street?: string | null, number?: string | null, complement?: string | null, city?: string | null, state?: string | null, country?: string | null, zip_code?: string | null, formatted_address?: string | null, timezone?: string | null, is_current?: boolean | null, location?: { __typename?: 'LocationType', coordinates?: Array<number | null> | null } | null } | null> | null } | null };

export type FetchUserByIdQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;


export type FetchUserByIdQuery = { __typename?: 'Query', userByID?: { __typename?: 'User', _id: string, email: string, firstname?: string | null, lastname?: string | null, picture?: string | null, Company?: Array<{ __typename?: 'Company', _id: string, name: string, employees?: Array<{ __typename?: 'User', _id: string, firstname?: string | null, lastname?: string | null, email: string, wallet?: { __typename?: 'UserWalletType', credits?: number | null } | null } | null> | null, users?: Array<{ __typename?: 'CompanyUsersType', _id?: string | null, status?: CompanyUserStatus | null } | null> | null } | null> | null } | null };


export const ImportEmployeesDocument = gql`
    mutation importEmployees($_company_id: ID!, $base64_file: String!, $file_name: String!) {
  importEmployees(
    _company_id: $_company_id
    base64_file: $base64_file
    file_name: $file_name
  ) {
    _id
  }
}
    `;
export type ImportEmployeesMutationFn = Apollo.MutationFunction<ImportEmployeesMutation, ImportEmployeesMutationVariables>;

/**
 * __useImportEmployeesMutation__
 *
 * To run a mutation, you first call `useImportEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importEmployeesMutation, { data, loading, error }] = useImportEmployeesMutation({
 *   variables: {
 *      _company_id: // value for '_company_id'
 *      base64_file: // value for 'base64_file'
 *      file_name: // value for 'file_name'
 *   },
 * });
 */
export function useImportEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<ImportEmployeesMutation, ImportEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportEmployeesMutation, ImportEmployeesMutationVariables>(ImportEmployeesDocument, options);
      }
export type ImportEmployeesMutationHookResult = ReturnType<typeof useImportEmployeesMutation>;
export type ImportEmployeesMutationResult = Apollo.MutationResult<ImportEmployeesMutation>;
export type ImportEmployeesMutationOptions = Apollo.BaseMutationOptions<ImportEmployeesMutation, ImportEmployeesMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($email: String!, $old_password: String!, $new_password: String!) {
  changePassword(
    email: $email
    old_password: $old_password
    new_password: $new_password
  ) {
    _id
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      old_password: // value for 'old_password'
 *      new_password: // value for 'new_password'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($user: CreateUserInput!) {
  createUser(user: $user) {
    _id
    email
    firstname
    lastname
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    _id
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginAdminDocument = gql`
    mutation loginAdmin($email: String!, $password: String!) {
  loginAdmin(email: $email, password: $password) {
    _id
    email
    picture
    firstname
    lastname
  }
}
    `;
export type LoginAdminMutationFn = Apollo.MutationFunction<LoginAdminMutation, LoginAdminMutationVariables>;

/**
 * __useLoginAdminMutation__
 *
 * To run a mutation, you first call `useLoginAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAdminMutation, { data, loading, error }] = useLoginAdminMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginAdminMutation(baseOptions?: Apollo.MutationHookOptions<LoginAdminMutation, LoginAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginAdminMutation, LoginAdminMutationVariables>(LoginAdminDocument, options);
      }
export type LoginAdminMutationHookResult = ReturnType<typeof useLoginAdminMutation>;
export type LoginAdminMutationResult = Apollo.MutationResult<LoginAdminMutation>;
export type LoginAdminMutationOptions = Apollo.BaseMutationOptions<LoginAdminMutation, LoginAdminMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($_id: ID!, $user: UserInput!) {
  updateUser(_id: $_id, user: $user) {
    _id
    email
    firstname
    lastname
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const FetchCompanyByIdDocument = gql`
    query FetchCompanyByID($_id: ID!) {
  companyByID(_id: $_id) {
    _id
    name
    users {
      _id
      status
    }
  }
}
    `;

/**
 * __useFetchCompanyByIdQuery__
 *
 * To run a query within a React component, call `useFetchCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompanyByIdQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useFetchCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<FetchCompanyByIdQuery, FetchCompanyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCompanyByIdQuery, FetchCompanyByIdQueryVariables>(FetchCompanyByIdDocument, options);
      }
export function useFetchCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCompanyByIdQuery, FetchCompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCompanyByIdQuery, FetchCompanyByIdQueryVariables>(FetchCompanyByIdDocument, options);
        }
export type FetchCompanyByIdQueryHookResult = ReturnType<typeof useFetchCompanyByIdQuery>;
export type FetchCompanyByIdLazyQueryHookResult = ReturnType<typeof useFetchCompanyByIdLazyQuery>;
export type FetchCompanyByIdQueryResult = Apollo.QueryResult<FetchCompanyByIdQuery, FetchCompanyByIdQueryVariables>;
export const FetchUserByEmailDocument = gql`
    query FetchUserByEmail($email: String!) {
  userByEmail(email: $email) {
    _id
    email
    firstname
    lastname
    picture
    addresses {
      _id
      type
      name
      street
      number
      complement
      city
      state
      country
      zip_code
      formatted_address
      timezone
      is_current
      location {
        coordinates
      }
    }
  }
}
    `;

/**
 * __useFetchUserByEmailQuery__
 *
 * To run a query within a React component, call `useFetchUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFetchUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<FetchUserByEmailQuery, FetchUserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUserByEmailQuery, FetchUserByEmailQueryVariables>(FetchUserByEmailDocument, options);
      }
export function useFetchUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUserByEmailQuery, FetchUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUserByEmailQuery, FetchUserByEmailQueryVariables>(FetchUserByEmailDocument, options);
        }
export type FetchUserByEmailQueryHookResult = ReturnType<typeof useFetchUserByEmailQuery>;
export type FetchUserByEmailLazyQueryHookResult = ReturnType<typeof useFetchUserByEmailLazyQuery>;
export type FetchUserByEmailQueryResult = Apollo.QueryResult<FetchUserByEmailQuery, FetchUserByEmailQueryVariables>;
export const FetchUserByIdDocument = gql`
    query FetchUserByID($_id: ID!) {
  userByID(_id: $_id) {
    _id
    email
    firstname
    lastname
    picture
    Company {
      _id
      name
      employees {
        _id
        firstname
        lastname
        email
        wallet {
          credits
        }
      }
      users {
        _id
        status
      }
    }
  }
}
    `;

/**
 * __useFetchUserByIdQuery__
 *
 * To run a query within a React component, call `useFetchUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserByIdQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useFetchUserByIdQuery(baseOptions: Apollo.QueryHookOptions<FetchUserByIdQuery, FetchUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUserByIdQuery, FetchUserByIdQueryVariables>(FetchUserByIdDocument, options);
      }
export function useFetchUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUserByIdQuery, FetchUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUserByIdQuery, FetchUserByIdQueryVariables>(FetchUserByIdDocument, options);
        }
export type FetchUserByIdQueryHookResult = ReturnType<typeof useFetchUserByIdQuery>;
export type FetchUserByIdLazyQueryHookResult = ReturnType<typeof useFetchUserByIdLazyQuery>;
export type FetchUserByIdQueryResult = Apollo.QueryResult<FetchUserByIdQuery, FetchUserByIdQueryVariables>;